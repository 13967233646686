import * as React from "react";

import { Link, useStaticQuery, graphql } from "gatsby";
import Layout from "../templates/layout";

const Resources = () => {
  return (
    <>
      <Layout>
        <main>
          <title>Resources</title>

          <h1>Resources</h1>

          <div>
            <Link to="https://www.csecpastpapers.com/subject-areas-all">
              CSEC Past Papers
            </Link>
            <Link to="https://www.youtube.com/channel/UCuJclBDpVFfDZs0OIMBgWTA">
                'CSEC Physics' Youtube Channel
            </Link>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default Resources;
